<template>
  <div class="mofang">
    <MySysForm v-model="itemData" :options="options" @change="change" />
  </div>
</template>

<script>
import MySysForm from "@/components/my-sys-form/index.vue";
// import Vuex from "vuex";
export default {
  name: "mofang",
  components: { MySysForm },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  watch: {
    "itemData.style.type": {
      handler(e) {
        console.log("itemData typetypetype", e);
        const arr = [];
        let num = e + 1;
        for (let index = 0; index < num; index++) {
          const item = this.itemData.imgList[index];
          if (item) {
            arr.push(item);
          } else {
            arr.push({ title: "导航一", link: {}, image: "" });
          }
        }
        this.itemData.imgList = arr;
      },
      deep: true,
    },
  },
  data() {
    return {
      options: [
        {
          type: "icon-buttons",
          model: "style$$type",
          option: {
            buttonLabel: "魔方类型",
            hideText: true,
            dictType: "MOFANG_TYPE",
          },
        },
        {
          type: "mofang",
          model: "imgList",
          option: {
            buttonLabel: "魔方类型",
          },
        },
        {
          type: "imgs",
          model: "imgList",
          option: {
            buttonLabel: "魔方类型",
          },
        },
        {
          type: "slider",
          model: "style$$marT",
          option: {
            buttonLabel: "上边距",
          },
        },
        {
          type: "slider",
          model: "style$$marB",
          option: {
            buttonLabel: "下边距",
          },
        },
        {
          type: "slider",
          model: "style$$marL",
          option: {
            buttonLabel: "左边距",
          },
        },
        {
          type: "slider",
          model: "style$$marR",
          option: {
            buttonLabel: "右边距",
          },
        },
        {
          type: "slider",
          model: "style$$marN",
          option: {
            buttonLabel: "内间隔",
          },
        },

        {
          type: "slider",
          model: "style$$borRadius",
          option: {
            buttonLabel: "圆角",
          },
        },
      ],
      itemData: this.data,
    };
  },
  methods: {
    ...Vuex.mapActions({
      setTemplateRightData: "setTemplateRightData",
      setTemplateTempData: "setTemplateTempData",
    }),
    change(e) {
      const data = this.$store.state.template.tempData;
      data[this.$store.state.template.selectIndex] = this.itemData;
      this.setTemplateRightData(this.itemData);
      this.setTemplateTempData(data);
      // this.$store.dispatch("template/setTemplateTempData", data);
      // this.$store.dispatch("template/setTemplateRightData", this.itemData);
    },
  },
};
</script>
