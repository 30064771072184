<template>
  <div style="width: 100%">
    <div v-if="!isStyle">
      <!-- 导航样式 -->
      <div class="marB20 x-bc">
        <div class="fontS14c3">导航样式</div>
        <div class="x-x">
          <div class="simpleBtn cursorP" :class="{ active: !data.contentStyle.isCustom }"
            @click="getShow('contentStyle', 'isCustom', false)">
            默认图标
          </div>
          <div class="simpleBtn cursorP" :class="{ active: data.contentStyle.isCustom}"
            @click="getShow('contentStyle', 'isCustom', true)">
            自定义
          </div>
        </div>
      </div>
      <div v-if="data.contentStyle.isCustom">
        <!-- 标签格式 -->
        <div class="marB20 x-bc">
          <div class="fontS14c3">{{`当前使用：${data.contentStyle.typeName||''}`}}</div>
          <div class="simpleBtn cursorP active" @click="isStyle=true">
            切换样式
          </div>
        </div>
        <!-- 导航数量 -->
        <div class="x-bc marB20">
          <div class="fontS14">
            导航数量
          </div>
          <div>
            <el-select ref="navNumber" size="mini" v-model="data.contentStyle.navNumber" filterable placeholder="请选择"
              @change="handNavNumber">
              <el-option v-for="item in [1,2,3,4,5]" :key="item" :label="item" :value="item" />
            </el-select>
          </div>
        </div>
        <!-- 导航栏 -->
        <div class="iconSettings" v-for="(item, index) in data.contentStyle.navList" :key="index">
          <div class="settinTop x-bc">
            <div class="y-start">
              <el-select :disabled='item.type==0&&index==0' size="mini" v-model="item.type" filterable placeholder="请选择"
                class="marB10" @change="changeNav(index,item.type)">
                <el-option v-for="(v,i) in navigation" :key="i" :label="v.name" :value="v.type" />
              </el-select>
              <div class="x-f">
                <el-input size="mini" type="text" v-model="item.label" maxlength="4" show-word-limit
                  placeholder="请输入" />
                <div v-if="item.type==4" class="x-f" @click="getOpenLink(index)">
                  <el-input size="mini" v-model="item.linkName" placeholder="请选择" readonly>
                  </el-input>
                  <div style="width:50px" class="marL10 fontS14B cursorP">
                    {{ item.linkUrl ? '修改' : '选择' }}</div>
                </div>
              </div>
            </div>
            <div class="x-f" v-show="data.contentStyle.type!=3">
              <div style="margin-right:20px">
                <div class="fontS14c3 iconTitle">普通:</div>
                <div class="itemIcon x-fc" @click="addImgClick(index,1)">
                  <i v-show="!item.icon" class="el-icon-plus" style="font-size: 24px"></i>
                  <el-image :src='item.icon' style="width:24px;height:24px" />
                  <span v-show="item.icon" class="replace">替换</span>
                </div>
              </div>
              <div>
                <div class="fontS14c3 iconTitle">点亮:</div>
                <div class="itemIcon x-fc" @click="addImgClick(index,2)">
                  <i v-show="!item.checkIcon" class="el-icon-plus" style="font-size: 24px"></i>
                  <el-image :src='item.checkIcon' style="width:24px;height:24px" />
                  <span v-show="item.checkIcon" class="replace">替换</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  普通颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">普通字体颜色:</span>
            <span class="fontS14">{{ data.contentStyle.textColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.contentStyle.textColor" />
          </div>
        </div>
        <!--  点亮颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">点亮字体颜色:</span>
            <span class="fontS14">{{ data.contentStyle.checkTextColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.contentStyle.checkTextColor" />
          </div>
        </div>
        <!--  背景颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">背景颜色:</span>
            <span class="fontS14">{{ data.contentStyle.bgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.contentStyle.bgColor" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="marB20 x-b">
        <div class="fontS14c3">切换样式</div>
        <div class="simpleBtn cursorP active" @click="isStyle=false">
          返回
        </div>
      </div>
      <div>
        <el-radio-group v-model="data.contentStyle.type" class="y-start" @change="getType">
          <div style="margin-bottom:40px" v-for="(x,i) in typeList" :key="i" class="y-start">
            <el-radio class="marB20" :label="x.type">{{x.typeName}}</el-radio>
            <el-image :src='x.url' />
          </div>
        </el-radio-group>
      </div>
    </div>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue'; //素材库
import selectLink from '@/views/components/selectLink/index.vue'; //链接库
export default {
  name: 'navigation',
  components: { selectLink, Material },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      addImgItem: null, // 添加图片时记录的列表下标
      selectImgIndex: null, // 选择图片的数组下标
      accessUrlList: [], // 选择的图片
      imgListIndex: null, // 标记选择链接的ITEN
      showDialog: false,
      navigation: [
        {
          name: '首页',
          linkName: '首页',
          linkUrl: '/pages/tabbar/home/index',
          type: 0
        },
        {
          name: '分类',
          linkName: '分类',
          linkUrl: '/pages/tabbar/category/index',
          type: 1
        },
        {
          name: '购物车',
          linkName: '购物车',
          linkUrl: '/pages/tabbar/shoppCart/index',
          type: 2
        },
        {
          name: '我的',
          linkName: '我的',
          linkUrl: '/pages/tabbar/my/index',
          type: 3
        },
        {
          name: '自定义',
          linkName: '',
          linkUrl: '',
          type: 4
        }
      ],
      isStyle: false,
      typeList: [
        {
          type: 0,
          typeName: '图标导航',
          url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-06/118e74e982f84d558221140ce18545a4.png'
        },
        {
          type: 1,
          typeName: '悬浮导航',
          url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-06/800c7d598aa34975a6de9aba853c453a.png'
        },
        {
          type: 2,
          typeName: '鸵式导航',
          url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-06/f0ab2d7937854fefaa25c8f3790efe7b.png'
        },
        {
          type: 3,
          typeName: '文字导航',
          url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-06/bd2d6604aac244609dd7c23fef6a70a7.png'
        },
        // {
        //   type: 4,
        //   typeName: '独立购物车导航',
        //   url: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-01/89670f6f0ae643a481d0b8afea989f57.png'
        // },
      ],
      linkIndex: -1, //当前打开的链接库是哪个数据
      openLink: false, //链接弹窗
    }
  },
  methods: {
    handNavNumber(value) {
      let oldVal = this.$refs.navNumber.value
      if (oldVal < value) {
        this.data.contentStyle.navList = this.extendArrayBasedOnNum(this.data.contentStyle.navList, value)
      } else {
        this.data.contentStyle.navList = this.data.contentStyle.navList.slice(0, value);
      }
      this.templateMsg()
    },
    extendArrayBasedOnNum(originalArray, num) {
      let extendedArray = [...originalArray];
      for (let i = originalArray.length; i < num; i++) {
        extendedArray.push({
          name: '自定义',
          type: 4,
          label: '',
          linkName: '自定义',
          linkUrl: '',
          icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
          checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
        });
      }
      return extendedArray;
    },
    // 添加图片
    addImgClick(index, type = 1) {
      this.selectImgIndex = index
      this.selectImgType = type
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value) {
      this.accessUrlList = value
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      if (this.selectImgType == 2) {
        this.data.contentStyle.navList[this.selectImgIndex].checkIcon = this.accessUrlList[0]
      } else {
        this.data.contentStyle.navList[this.selectImgIndex].icon = this.accessUrlList[0]
      }
      this.accessUrlList = []
      this.showDialog = false
      this.templateMsg()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    changeNav(index, type) {
      let obj = this.navigation.find(x => x.type == type)
      this.data.contentStyle.navList[index].name = obj.name
      this.data.contentStyle.navList[index].linkName = obj.linkName
      this.data.contentStyle.navList[index].linkUrl = obj.linkUrl
      this.templateMsg()
    },
    getType(val) {
      switch (val) {
        case 0:
        case 1:
        case 3:
          this.data.contentStyle.navNumber = 4
          this.data.contentStyle.navList = [
            {
              name: '首页',
              type: 0,
              label: '',
              linkName: '首页',
              linkUrl: '/pages/tabbar/home/index',
              icon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/a2c2ef4494fe46ab8fc4211b8cdfea58.png" : null,
              checkIcon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/ec56f5c2f41e4d71889167df557a9de9.png" : null,
            },
            {
              name: '分类',
              type: 1,
              label: '',
              linkName: '分类',
              linkUrl: '/pages/tabbar/category/index',
              icon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/448b61f0b8424512a682cbe18d685d66.png" : null,
              checkIcon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/6afef13c189d43a196b455cd205dcfb3.png" : null,
            },
            {
              name: '购物车',
              type: 2,
              label: '',
              linkName: '购物车',
              linkUrl: '/pages/tabbar/shoppCart/index',
              icon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/925658da76b147b7a7a5a37bc87c3097.png" : null,
              checkIcon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/368bc8489ee74539844c28a279e33af7.png" : null,
            },
            {
              name: '我的',
              type: 3,
              label: '',
              linkName: '我的',
              linkUrl: '/pages/tabbar/my/index',
              icon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/3e5f2a7620d741e9b1f0d85207affd98.png" : null,
              checkIcon: val != 3 ? "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/0058e713b0c7475f9d81a7537a2b1e7b.png" : null,
            }
          ]
          break
        case 2:
          this.data.contentStyle.navNumber = 5
          this.data.contentStyle.navList = [
            {
              name: '首页',
              type: 0,
              label: '',
              linkName: '首页',
              linkUrl: '/pages/tabbar/home/index',
              icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/a2c2ef4494fe46ab8fc4211b8cdfea58.png",
              checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/ec56f5c2f41e4d71889167df557a9de9.png",
            },
            {
              name: '分类',
              type: 1,
              label: '',
              linkName: '分类',
              linkUrl: '/pages/tabbar/category/index',
              icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/448b61f0b8424512a682cbe18d685d66.png",
              checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/6afef13c189d43a196b455cd205dcfb3.png",
            },
            {
              name: '',
              type: 2,
              label: '',
              linkName: '购物车',
              linkUrl: '/pages/tabbar/shoppCart/index',
              icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-05/dd89d801c2b648f4be17d2520a6a5b06.png",
              checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-05/dd89d801c2b648f4be17d2520a6a5b06.png",
            },
            {
              name: '会员卡',
              type: 4,
              label: '',
              linkName: '会员卡',
              linkUrl: '/subPackages/vipCard/index',
              icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-05/a1a7d0d871f44d57a9a90b6b80e6d58a.png",
              checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-05/a9475f68c92f4467991cdb5501882243.png",
            },
            {
              name: '我的',
              type: 3,
              label: '',
              linkName: '我的',
              linkUrl: '/pages/tabbar/my/index',
              icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/3e5f2a7620d741e9b1f0d85207affd98.png",
              checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/0058e713b0c7475f9d81a7537a2b1e7b.png",
            }
          ]
          break
        default:
          break
      }
      this.getShow('contentStyle', 'typeName', this.typeList[val].typeName)
      this.isStyle = !this.isStyle
      this.templateMsg()
    },
    //链接赋值
    selectLinkObj(value) {
      this.data.contentStyle.navList[this.linkIndex].linkName = value.name
      this.data.contentStyle.navList[this.linkIndex].linkUrl = value.url
      this.templateMsg()
    },
    //打开链接库
    getOpenLink(index) {
      this.openLink = true
      this.linkIndex = index
    },
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow(typeObj, type, bol) {
      this.data[typeObj][type] = bol
      this.templateMsg()
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
  }
}
</script>

<style lang="scss" scoped>
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//内容设置
.iconSettings {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  margin-bottom: 20px;
  //选择框
  .settinTop {
    padding: 15px 10px;
    background-color: #f8f9ff;
    .iconTitle {
      margin-bottom: 5px;
    }
    //图标
    .itemIcon {
      width: 70px;
      height: 70px;
      background: #ffffff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #f4f4f4;
      position: relative;
      //替换
      .replace {
        position: absolute;
        height: 20px;
        left: 1px;
        right: 1px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        line-height: 20px;
        margin: 0;
        text-align: center;
        display: none;
      }
    }
    //替换
    .itemIcon:hover .replace {
      display: inline-block;
    }
    //输入框
    .inputWidthNav {
      width: 100px;
    }
  }
  //内容设置
  .item {
    margin: 20px 0 0 30px;
    //图标标题
    .iconTitle {
      width: 85px;
    }
    //图标
    .itemIcon {
      width: 70px;
      height: 70px;
      background: #ffffff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #f4f4f4;
      position: relative;
      //替换
      .replace {
        position: absolute;
        height: 20px;
        left: 1px;
        right: 1px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        line-height: 20px;
        margin: 0;
        text-align: center;
        display: none;
      }
    }
    //替换
    .itemIcon:hover .replace {
      display: inline-block;
    }
  }
}

/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
