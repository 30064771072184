<template>
    <div style="width: 100%">
        <!-- 选择布局 -->
        <div class="marB20">
            <div class="flex">
                <span>魔方布局：</span>
                <div>
                    <el-radio-group v-model="tabPosition" @input="changeBox">
                        <el-radio-button label="1">
                            <!-- <el-avatar :src="YH"></el-avatar> -->
                            <!-- <el-avatar shape="square" :size="20" :fit="'fill'" src="../../../../../../../assets/icons/shopicon/1.png"></el-avatar> -->
                            <el-image
                            style="width: 20px; height: 20px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/5be0108e1dc841d2a265ed554f1229bf.png"
                            fit="fill"></el-image>
                        </el-radio-button>
                        <el-radio-button label="2">
                            <!-- <el-avatar :src="YR"></el-avatar> -->
                            <el-image
                            style="width: 20px; height: 20px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/acc2e79d49684920b6876a649156848b.png"
                            fit="fill"></el-image>
                        </el-radio-button>
                        <el-radio-button label="3">
                            <el-image
                            style="width: 20px; height: 20px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/e9a4cd910e784716bb1a5d732648c7d5.png"
                            fit="fill"></el-image>
                        </el-radio-button>
                        <el-radio-button label="4">
                            <el-image
                            style="width: 20px; height: 20px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/b2b807e1fc9c4b3d8cd593e0cfd83ef2.png"
                            fit="fill"></el-image>
                        </el-radio-button>
                        <el-radio-button label="5">
                            <el-image
                            style="width: 20px; height: 20px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/2ecd0d34282348e2afb0341c4d170ed0.png"
                            fit="fill"></el-image>
                        </el-radio-button>
                    </el-radio-group>
                    <div style="display: flex;justify-content: space-between;">
                        <span>一行两个</span>
                        <span>一行三个</span>
                        <span>左一右二</span>
                        <span>上一下二</span>
                        <span>左一右三</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容 -->
        <div>
            <!--  -->
            <div class="flex" v-if="tabPosition === '1'">
                <div :class="['cube', upIndex + 1 === 1 ? 'active' : 'active-1']" @click="upImgIndex(1)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[0].url" fit="cover"
                        v-if="data.imgList[0]">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div :class="['cube', upIndex + 1 === 2 ? 'active' : 'active-1']" @click="upImgIndex(2)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[1].url" fit="cover"
                        v-if="data.imgList[1]">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
            </div>
            <!--  -->
            <div class="flex" v-if="tabPosition === '2'">
                <div :class="['cube-1', upIndex + 1 === 1 ? 'active' : 'active-1']" @click="upImgIndex(1)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[0].url" fit="cover">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div :class="['cube-1', upIndex + 1 === 2 ? 'active' : 'active-1']" @click="upImgIndex(2)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[1].url" fit="cover"
                        v-if="data.imgList[1]">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div :class="['cube-1', upIndex + 1 === 3 ? 'active' : 'active-1']" @click="upImgIndex(3)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[2].url" fit="cover"
                        v-if="data.imgList[2]">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
            </div>
            <!--  -->
            <div class="flex" v-if="tabPosition === '3'">
                <div :class="['cube dz', upIndex + 1 === 1 ? 'active' : 'active-1']" @click="upImgIndex(1)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[0].url" fit="cover">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div class="cube-three">
                    <div :class="['cube-3', upIndex + 1 === 2 ? 'active' : 'active-1']" @click="upImgIndex(2)">
                        <el-image style="width: 100%; height: 100%" :src="data.imgList[1].url" fit="cover"
                            v-if="data.imgList[1]">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                    <div :class="['cube-3', upIndex + 1 === 3 ? 'active' : 'active-1']" @click="upImgIndex(3)">
                        <el-image style="width: 100%; height: 100%" :src="data.imgList[2].url" fit="cover"
                            v-if="data.imgList[2]">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
            <!--  -->
            <div v-if="tabPosition === '4'">
                <div :class="['cube tz', upIndex + 1 === 1 ? 'active' : 'active-1']" @click="upImgIndex(1)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[0].url" fit="cover">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div class="cube-fro">
                    <div :class="['cube', upIndex + 1 === 2 ? 'active' : 'active-1']" @click="upImgIndex(2)">
                        <el-image style="width: 100%; height: 100%" :src="data.imgList[1].url" fit="cover"
                            v-if="data.imgList[1]">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                    <div :class="['cube', upIndex + 1 === 3 ? 'active' : 'active-1']" @click="upImgIndex(3)">
                        <el-image style="width: 100%; height: 100%" :src="data.imgList[2].url" fit="cover"
                            v-if="data.imgList[2]">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="flex" v-if="tabPosition === '5'">
                <div :class="['cube dz', upIndex + 1 === 1 ? 'active' : 'active-1']" @click="upImgIndex(1)">
                    <el-image style="width: 100%; height: 100%" :src="data.imgList[0].url" fit="cover">
                        <div slot="error" class="image-slot">
                            <div>
                                <i class="el-icon-plus"></i>
                                <div>上传图片</div>
                            </div>
                        </div>
                    </el-image>
                </div>
                <div class="cube-three">
                    <div :class="['cube-3', upIndex + 1 === 2 ? 'active' : 'active-1']" @click="upImgIndex(2)">
                        <el-image style="width: 100%; height: 100%" :src="data.imgList[1].url" fit="cover"
                            v-if="data.imgList[1]">
                            <div slot="error" class="image-slot">
                                <div>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </el-image>
                    </div>
                    <div class="flex">
                        <div :class="['cube-2', upIndex + 1 === 3 ? 'active' : 'active-1']" @click="upImgIndex(3)">
                            <el-image style="width: 100%; height: 100%" :src="data.imgList[2].url" fit="cover"
                                v-if="data.imgList[2]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                        <div :class="['cube-2', upIndex + 1 === 4 ? 'active' : 'active-1']" @click="upImgIndex(4)">
                            <el-image style="width: 100%; height: 100%" :src="data.imgList[3].url" fit="cover"
                                v-if="data.imgList[3]">
                                <div slot="error" class="image-slot">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="marT20" style="background: #F8F9FF;">
                <div class="boxDetail marB10 flex" style="align-items: center;padding: 10px;">
                    <!-- 按钮图标 -->
                    <div class="x-x marB10">
                        <div class="selectIconBox x-fc active-1" style="position:relative">
                            <el-image  @click="getReplace()" style="width: 70px; height: 70px" :src="boxes.url" fit="cover">
                                <div slot="error" class="image-slot-1">
                                    <div>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </el-image>
                            <span v-show="boxes.url" class="replace"  @click="getReplace()">替换</span>
                    <!-- 删除 -->
                            <div style="width: 100%;position: absolute;top: 0;right: 0;text-align: right;">
                                <i class="el-icon-delete"
                                    v-show="boxes.url"
                                    style=""
                                    @click="delIcon"
                                    >
                                </i>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <!-- 跳转链接 -->
                        <div class="x-f link" @click="getOpenLink()" style="color: cornflowerblue;">
                            <el-input class="inputWidth" size="mini" v-model="boxes.link.name" readonly
                                placeholder="选择链接" />
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div>
            <!-- 内边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">内边距</span>
                <el-slider class="slider" v-model="data.style.marO" :min="0" :max="10" />
            </div>
            <!-- 上边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">上边距</span>
                <el-slider class="slider" v-model="data.style.marT" :min="0" :max="50" />
            </div>
            <!-- 下边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">下边距</span>
                <el-slider class="slider" v-model="data.style.marB" :min="0" :max="50" />
            </div>
            <!-- 左边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">左边距</span>
                <el-slider class="slider" v-model="data.style.marL" :min="0" :max="25" />
            </div>
            <!-- 右边距 -->
            <div class="x-f marB20">
                <span class="fontS14 marR50">右边距</span>
                <el-slider class="slider" v-model="data.style.marR" :min="0" :max="25" />
            </div>
            <!-- 圆角 -->
            <div class="x-f marB20">
                <span class="fontS14 radiuDistance">圆角</span>
                <el-slider class="slider" v-model="data.style.borRadius" :min="0" :max="50" />
            </div>
        </div>
        <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
        <el-dialog title="素材库" :visible.sync="openIcon" width="80%" :destroy-on-close="true">
            <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
                <el-button @click="imgUpdateCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import LD from 'lodash'
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import Material from '@/views/components/material/index.vue' //素材库
// import YH from '../../../../../../../assets/icons/shopicon/1.png'
// import YR from '@/assets/icons/shopicon/2.png'
export default {
    name: 'rubikcube',
    components: { selectLink, Material },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            openLink: false, //链接弹窗
            tabPosition: '1',
            boxes: {
                url: '',
                link: '',
                name: ''
            },
            openIcon: false,
            accessUrlList: [],
            upIndex: 0,
        };
    },
    created() {
        this.boxes = this.data.imgList[0]
        this.tabPosition = this.data.style.type
    },
    methods: {
        changeBox(value) {
            this.data.style.type = value
        },
        getOpenLink(index) {
            this.openLink = true
            // this.linkIndex = index
        },
        getReplace() {
            this.openIcon = true
        },
        delIcon() {
            console.log('----12333---');
            this.data.imgList[this.upIndex].url = ''
        },
        //链接赋值
        selectLinkObj(value) {
            this.data.imgList[this.upIndex].link = value
            // this.templateMsg()
        },
        // 上传图片
        upImgIndex(value) {
            this.upIndex = value - 1
            this.boxes = this.data.imgList[this.upIndex] || {
                url: '',
                link: '',
                name: ''
            }
            console.log('---index', value, this.data);
        },
        getAccessUrlList(value) {
            console.log('选择的图片：', value)
            this.accessUrlList = value
        },
        // 选择图片弹框确定
        imgUpdateSubmit() {
            if (this.accessUrlList.length > 1) {
                return this.$message.error('图片信息只能单选')
            }
            if (!!this.data.imgList[this.upIndex]) {
                this.data.imgList.push({
                    url: '',
                    link: '',
                    name: ''
                })
            }
            this.data.imgList[this.upIndex].url = this.accessUrlList[0]
            this.accessUrlList = []
            this.openIcon = false
            // this.templateMsg()
        },
        imgUpdateCancel() {
            this.accessUrlList = []
            this.openIcon = false
        },
    }
};
</script>
  
<style lang="scss" scoped>
::v-deep .el-radio-button--medium .el-radio-button__inner {
    padding: 5px 15px;
}
//选择图标
.selectIconBox {
    width: 70px;
    height: 70px;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f4f4f4;
    position: relative;

    //替换
    .replace {
        position: absolute;
        height: 20px;
        left: 1px;
        right: 1px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        line-height: 20px;
        margin: 0;
        text-align: center;
        display: none;
    }
    .el-icon-delete {
        display: none;
    }
}

//替换
.selectIconBox:hover .replace {
    display: inline-block;
}
.selectIconBox:hover .el-icon-delete {
    display: inline-block;
}

.flex {
    display: flex
}

.cube {
    flex: 1;
    // width: 180px;
    height: 100px;
    // background-color: pink
}

.cube-1 {
    width: 120px;
    height: 120px;
    // background-color: pink
}

.dz {
    height: 360px
}

// .cube-5 {
//     width: 180px;
//     height: 180px;
//     background-color: pink
// }
.tz {
    width: 360px
}

.cube-fro {
    display: flex;
}

.cube-2 {
    width: 90px;
    height: 180px;
    // background-color: pink
}

.slider {
    width: calc(100% - 106px);
}

//圆角
.radiuDistance {
    margin-right: 62px;
}

.cube-3 {
    width: 180px;
    height: 180px;
    // background-color: pink
}

::v-deep .image-slot {
    text-align: center;
    margin-top: 30px;
}
::v-deep .image-slot-1 {
    text-align: center;
    margin-top: 25px;
}
.active {
    border: 1px solid #4390FF;
}
.active-1 {
    border: 1px solid #ccc;
}
</style>
  