<template>
  <div class="videoTmp">
    <el-form :model="data" @submit.native.prevent>
      <el-form-item label="视频:">
        <div class="x-bc">
          <div class="x-f"></div>
          <div class="x-f btnListBox">
            <!-- <div
                            class="item x-c"
                            v-for="(item, index) in vadeoSource"
                            :key="index"
                            @click="setSource(item.source, index)"
                            :class="{ borderColor: sourceItem == index }"
                        >
                            <span>{{ item.name }}</span>
                        </div> -->
            <OssUpload
              v-model="data.style.videoUrl"
              :type="['mp4']"
              :showList="false"
              :tip="false"
              buttonName="上传视频"
              size="10mb"
            />
          </div>
        </div>
        <div class="y-start marT10">
          <el-input v-model="data.style.videoUrl" placeholder="请输入地址链接"></el-input>
          <span style="color: #969799" class="fS14MR10"
            >小程序 v2.15 及以上版本支持仅支持.mp4格式的视频源地址</span
          >
          <video
            style="background: #000000"
            :src="data.style.videoUrl"
            height="200px"
            width="95%"
            :poster="data.style.coverImg"
          >
            your browser does not support the video tag
          </video>
        </div>
      </el-form-item>
      <el-form-item label="封面:">
        <div class="x-bc">
          <div class="x-f"></div>
          <div class="x-f btnListBox fS14MR10">
            <div
              class="item x-c"
              v-for="(item, index) in coverImgList"
              :key="index"
              @click="setCover(item.style, index)"
              :class="{ borderColor: sourceItem == index }"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <div v-if="coverImgItem == 1">
        <div class="addImgBox x-c" @click="addCoverImg">
          <i
            class="el-icon-plus"
            v-if="data.style.coverImg"
            style="font-size: 26px; color: #bababa"
          ></i>
          <el-image
            v-else-if="data.style.coverImg != ''"
            :src="data.style.coverImg"
            width="100%"
          ></el-image>
        </div>
        <span style="color: #969799" class="fontS12">建议图宽高比例与视频宽高比一致</span>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="x-bc">
          <span class="title">播放设置</span>
          <i :class="jiantou1" class="fontS16 pointer" @click="openDownFn"></i>
        </div>
        <div v-if="openDown" class="marT10">
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">进度条</span>
                <span>{{ data.style.progressBar ? "显示" : "不显示" }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch v-model="data.style.progressBar" size="mini"></el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">自动播放</span>
                <span>{{ data.style.voluntarilyPlay ? "已开启" : "已关闭" }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch v-model="data.style.voluntarilyPlay" size="mini"></el-switch>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="x-bc">
          <span class="title">样式设置</span>
          <i :class="jiantou2" class="fontS16 pointer" @click="open"></i>
        </div>
        <div v-if="openChamfer" class="marT10">
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">视频倒角</span>
                <!--                                <span>{{data.style.progressBar ? '显示' : '不显示'}}</span>-->
              </div>
              <div class="x-f btnListBox">
                <div
                  class="item x-c"
                  v-for="(item, index) in chamferList"
                  :key="index"
                  @click="setChamfer(item.style, index)"
                  :class="{ borderColor: chamferItem == index }"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="上边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagePaddingTop"
                @change="selectpagebackGauge(data.style.pagePaddingTop, 'top')"
                show-input
              ></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="下边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagePaddingBottom"
                @change="selectpagebackGauge(data.style.pagePaddingBottom, 'bottom')"
                show-input
              ></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="左边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagePaddingLeft"
                @change="selectpagebackGauge(data.style.pagePaddingLeft, 'left')"
                show-input
              ></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="右边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagePaddingRight"
                @change="selectpagebackGauge(data.style.pagePaddingRight, 'right')"
                show-input
              ></el-slider>
            </div>
          </el-form-item>
          <!-- <el-form-item label="页面边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagebackGauge"
                show-input
              ></el-slider>
            </div>
          </el-form-item> -->
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import OssUpload from "@/components/oss-upload/index.vue";
export default {
  name: "videoTmp",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  components: {
    OssUpload,
  },
  data() {
    return {
      // 视频来源
      vadeoSource: [
        // { source: 'update', name: '选择视频' },
        { source: "url", name: "视频地址" },
      ],
      coverImgList: [
        { style: "none", name: "默认", src: "" },
        { style: "select", name: "选择图片", src: "" },
      ],
      chamferList: [
        { style: "rightAngle", name: "直角" },
        { style: "circle", name: "圆角" },
      ],

      jiantou1: "el-icon-arrow-down",
      jiantou2: "el-icon-arrow-down",
      openDown: false,
      openChamfer: false,

      sourceItem: 0,
      coverImgItem: 0,
      chamferItem: 0,
    };
  },
  methods: {
    selectpagebackGauge (value, type) {
      this.templateMsg()
    },
    templateMsg() {
      this.$emit("setTemp", this.data);
    },
    //
    setSource(style, index) {
      this.coverImgItem = index;
      // this.data.style.videoSource = style
    },
    //
    setCover(style, index) {
      this.coverImgItem = index;
      this.data.style.videoSource = style;
    },
    // 打开素材库选择封面
    addCoverImg() {
      this.$parent.$parent.materialOpen = true;
    },
    // 视频倒角
    setChamfer(style, index) {
      this.chamferItem = index;
      this.data.style.chamfer = style;
      this.templateMsg();
    },

    // 切换箭头
    openDownFn() {
      this.openDown = !this.openDown;
      if (this.openDown) {
        this.jiantou1 = "el-icon-arrow-up";
      } else {
        this.jiantou1 = "el-icon-arrow-down";
      }
    },
    open() {
      this.openChamfer = !this.openChamfer;
      if (this.openChamfer) {
        this.jiantou2 = "el-icon-arrow-up";
      } else {
        this.jiantou2 = "el-icon-arrow-down";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videoTmp {
}

.lable {
  margin-right: 16px;
  font-size: 14px;
  color: #d3d3d3;
  line-height: 18px;
  white-space: nowrap;
}

.btnListBox {
  border-radius: 5px;
  font-size: 12px;

  .item {
    //width: 38px;
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }

  .borderColor {
    border: 1px solid #1c75f4;
  }
}

.addImgBox {
  width: 80px;
  height: 80px;
  border: 1px solid #bababa;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: 550;
}
</style>
