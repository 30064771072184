<template>
    <div>
        <div>
            <h2>模板选择</h2>
            <el-radio-group v-model="data.style.templateNo" size="mini">
                <el-radio-button label="1">模板一</el-radio-button>
                <!--                <el-radio-button label="2">模板二</el-radio-button>-->
                <!--                <el-radio-button label="3">模板三</el-radio-button>-->
                <!--                <el-radio-button label="4">模板四</el-radio-button>-->
            </el-radio-group>
        </div>
        <div>
            <div class="x-bc">
                <h2>展示设置</h2>
                <i :class="zhanshiIcon" class="pointer" style="color: #000000;font-size: 20px" @click="openZhanShi"></i>
            </div>
            <div v-if="isOpenZhanShi">
                <div class="marT10" v-if="data.style.templateNo <= 2">
                    <span class="fontS14">客户信息</span>
                    <div class="x-bc marT10">
                        <div class="x-f">
                            <span class="marR20 lable color000">微信头像</span>
                        </div>
                        <div class="x-f btnListBox">
                            <div class="item x-c" style="color: #979797" v-for="(item, index) in switchList" :key="index"
                                :style="{ fontSize: item.fontSize + 'px' }" @click="switchBtn('isAvatar', index)"
                                :class="{ 'borderColor': avatarItem == index }">
                                {{ item.label }}
                            </div>
                            <!--                            <el-switch style="zoom: .8" :width="40" v-model="data.style.isAvatar"></el-switch>-->
                        </div>
                    </div>
                    <div class="x-bc marT10">
                        <div class="x-f">
                            <span class="marR20 lable color000">会员码</span>
                        </div>
                        <div class="x-f btnListBox">
                            <el-switch style="zoom: .8" :width="40" v-model="data.style.isVipQrCode"></el-switch>
                        </div>
                    </div>
                </div>
                <div class="marT10">
                    <span class="fontS14">资产信息</span>
                    <div v-if="data.style.templateNo == 1">
                        <div class="x-bc marT10">
                            <div class="x-f">
                                <span class="marR20 lable color000">资产信息</span>
                            </div>
                            <div class="x-f btnListBox">
                                <el-select size="mini" style="width: 170px" v-model="data.style.description"
                                    placeholder="请选择" @change="descriptionChange">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="x-bc marT10">
                            <div class="x-f">
                                <span class="marR20 lable color000">资产描述</span>
                            </div>
                            <div class="x-f btnListBox">
                                <el-input size="mini" style="width: 170px" :maxlength="12" v-model="data.style.propertyMsg"
                                    placeholder="最多12个字以内，可不填"></el-input>
                            </div>
                        </div>
                    </div>
                    <div v-if="data.style.templateNo == 2">
                        <div class="descriptionArr">
                            <div class="item x-bc" v-for="(item, index) in options" :key="index">
                                <div class="x-f">
                                    <iconpark-icon name="align-text-both" size="20px"></iconpark-icon>
                                    <span class="marL10">{{ item.label }}</span>
                                </div>
                                <el-switch style="zoom: .8" :width="40" v-model="item.open"
                                    @change="descriptionArr(item)"></el-switch>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <div class="x-bc">
                <h2>样式设置</h2>
                <i :class="yangShiIcon" class="pointer" style="color: #000000;font-size: 20px" @click="openYangShi"></i>
            </div>
            <div v-if="isOpenYangShi">
                <div class="x-bc">
                    <div class="x-f">
                        <span class="marR20 lable color000">文字颜色</span>
                        <span>{{ data.style.textColor }}</span>
                    </div>
                    <div class="x-f btnListBox">
                        <el-color-picker v-model="data.style.textColor" @change="selectTextcolor"></el-color-picker>
                    </div>
                </div>
                <div class="x-bc marT10">
                    <div class="x-f">
                        <span class="marR20 lable color000">卡片背景</span>
                        <!--                        <span>{{data.style.location == 'y-start' ? '居中显示' : '左对齐'}}</span>-->
                    </div>
                    <div class="x-f btnListBox">
                        <div class="item x-c" v-for="(item, index) in bgStyleList" :key="index"
                            @click="setBgStyle(item.style, index)" :class="{ 'borderColor': bgStyleItem == index }">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="marT10">
                    <div v-if="this.data.style.bgStyle == 'image'">
                        <div class="imgBox x-c cursorP" @click="addImgClick()" style="overflow: hidden;">
                            <div class="y-c" v-if="data.style.bgImage == ''">
                                <i class="el-icon-plus" style="font-size: 20px; margin-bottom: 5px"></i>
                                <span>添加图片</span>
                            </div>
                            <el-image v-else-if="data.style.bgImage != ''" :src="data.style.bgImage"
                                width="100%"></el-image>
                        </div>
                        <span class="fontS12 marT10" style="color: #bababa;">建议尺寸： 1370x320 像素的图片</span>
                    </div>
                    <div v-else-if="this.data.style.bgStyle == 'color'">
                        <div class="x-bc">
                            <div class="x-f">
                                <span class="marR20 lable color000">背景颜色</span>
                                <span>{{ data.style.cardBgColor }}</span>
                            </div>
                            <div class="x-f btnListBox">
                                <el-color-picker v-model="data.style.cardBgColor"></el-color-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="x-bc marT10">
                    <div class="x-f">
                        <span class="marR20 lable color000">卡片倒角</span>
                        <!--                        <span>{{data.style.location == 'y-start' ? '居中显示' : '左对齐'}}</span>-->
                    </div>
                    <div class="x-f btnListBox">
                        <div class="item x-c" v-for="(item, index) in chamferList" :key="index"
                            @click="setChamfer(item.style, index)" :class="{ 'borderColor': chamferItem == index }">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="x-bc marT10">
                    <div class="x-f">
                        <span class="marR20 lable color000">卡片样式</span>
                    </div>
                    <div class="x-f btnListBox">
                        <el-select size="mini" style="width: 170px" v-model="data.style.cardStyle" @change="cardStyleChange"
                            placeholder="请选择">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <div class="x-bc marT10">
                    <div class="x-f">
                        <span class="marR20 lable">页面边距</span>
                    </div>
                    <div class="x-f btnListBox">
                        <el-slider style="width: 300px;" :max="30" v-model="data.style.pagePadding" show-input></el-slider>
                    </div>
                </div> -->
                <div>
                    <!-- 上边距 -->
                    <div class="x-f marB20">
                        <span class="fontS14 marR50">上边距</span>
                        <el-slider class="slider" v-model="data.marT" :min="0" :max="50" />
                    </div>
                    <!-- 下边距 -->
                    <div class="x-f marB20">
                        <span class="fontS14 marR50">下边距</span>
                        <el-slider class="slider" v-model="data.marB" :min="0" :max="50" />
                    </div>
                    <!-- 左边距 -->
                    <div class="x-f marB20">
                        <span class="fontS14 marR50">左边距</span>
                        <el-slider class="slider" v-model="data.marL" :min="0" :max="25" />
                    </div>
                    <!-- 右边距 -->
                    <div class="x-f marB20">
                        <span class="fontS14 marR50">右边距</span>
                        <el-slider class="slider" v-model="data.marR" :min="0" :max="25" />
                    </div>
                    <!-- 圆角 -->
                    <div class="x-f marB20">
                        <span class="fontS14 radiuDistance">圆角</span>
                        <el-slider class="slider" v-model="data.borRadius" :min="0" :max="50" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 图片选择弹窗 -->
        <el-dialog  v-dialogDrag title="素材库" :visible.sync="openIcon" width="80%" :destroy-on-close="true">
            <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
                <el-button @click="imgUpdateCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
export default {
    name: 'zichan',
    components: { Material },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            openIcon: false,
            zhanshiIcon: 'el-icon-arrow-up',
            isOpenZhanShi: true,
            yangShiIcon: 'el-icon-arrow-up',
            isOpenYangShi: true,
            switchList: [
                {
                    label: '显示',
                },
                {
                    label: '隐藏'
                }
            ],
            options: [
                { label: '积分', value: 1, open: true, num: 0 },
                { label: '优惠券', value: 2, open: true, num: 0 },
                { label: '余额', value: 3, open: true, num: 0 },
                // { label: '集点卡', value: 4, open: false, num: 0 },
                // { label: '权益卡', value: 5, open: false, num: 0 }
            ],
            options2: [
                { label: '无边白底', value: 1 },
                { label: '卡片投影', value: 2 },
                { label: '描边白底', value: 3 },
                { label: '无边透明底', value: 4 }
            ],
            bgStyleList: [
                { style: 'image', name: '图片' },
                { style: 'color', name: '颜色' }
            ],
            chamferList: [
                { style: 0, name: '直角' },
                { style: 10, name: '圆角' }
            ],

            bgStyleItem: 1,
            chamferItem: 0,
            avatarItem: 0, // 头像选项标记
            accessUrlList: []
        }
    },
    methods: {
        getAccessUrlList(value) {
            console.log('选择的图片：', value)
            this.accessUrlList = value
        },
        // 选择图片弹框确定
        imgUpdateSubmit() {
            if (this.accessUrlList.length > 1) {
                return this.$message.error('图片信息只能单选')
            }
            // this.data.style.imgList[this.selectImgIndex].image = this.accessUrlList[0]
            this.data.style.bgImage = this.accessUrlList[0]
            this.openIcon = false
        },
        imgUpdateCancel() {
            this.accessUrlList = []
            this.openIcon = false
        },
        openZhanShi() {
            if (this.zhanshiIcon == 'el-icon-arrow-up') {
                this.isOpenZhanShi = false
                this.zhanshiIcon = 'el-icon-arrow-down'
            } else {
                this.isOpenZhanShi = true
                this.zhanshiIcon = 'el-icon-arrow-up'
            }
        },
        openYangShi() {
            if (this.yangShiIcon == 'el-icon-arrow-up') {
                this.isOpenYangShi = false
                this.yangShiIcon = 'el-icon-arrow-down'
            } else {
                this.isOpenYangShi = true
                this.yangShiIcon = 'el-icon-arrow-up'
            }
        },
        templateMsg() {
            this.$emit('setTemp', this.data)
        },
        // 文字颜色
        selectTextcolor(value) {
            console.log('改变颜色：', value)
            if (value != '#FFFFFF') {
                this.data.style.cardStyle = 1
            }
            this.data.style.textColor = value
            this.templateMsg()
        },
        setBgStyle(style, index) {
            this.data.style.bgStyle = style
            this.bgStyleItem = index
            this.templateMsg()
        },
        // 添加图片
        addImgClick() {
            this.openIcon = true
            // this.$parent.$parent.materialOpen = true
        },
        // 直角圆角
        setChamfer(style, index) {
            this.data.style.chamfer = style
            this.chamferItem = index
            this.templateMsg()
        },
        cardStyleChange(val) {
            console.log('样式val:', val)
            this.data.style.cardStyle = val
            // if (val == 1) {
            //     this.data.style.cardStyle = '#ffffff'
            // } else if (val == 2) {
            //     this.data.style.cardStyle = '#f1f1f1'
            // } else if (val == 3) {
            //     this.data.style.cardStyle = '#ffffff'
            // } else if (val == 4) {
            //     this.data.style.bGopacity = 0
            // }
            this.templateMsg()
        },
        // 资产信息选择
        descriptionChange(val) {
            console.log('资产信息', val)
            if (val == 1) {
                this.data.style.descriptionText = '积分'
            } else if (val == 2) {
                this.data.style.descriptionText = '优惠券'
            } else if (val == 3) {
                this.data.style.descriptionText = '余额'
            } else if (val == 4) {
                this.data.style.descriptionText = '集点卡'
            } else if (val == 5) {
                this.data.style.descriptionText = '权益卡'
            }
            this.templateMsg()
        },
        // 头像选择切换
        switchBtn(str, index) {
            this.avatarItem = index
            this.data.style[str] = index == 0 ? true : false // 头像是否显示
        },
        // 选择要显示的会员信息
        descriptionArr(val) {
            console.log('会员信息展示选择', val)
            if (this.data.style.descriptionArr.length >= 3) {
                this.$message.info('最多支持开启3个资产')
            } else {
                this.data.style.descriptionArr.push(val)
            }
            this.data.style.descriptionArr.forEach((item, index) => {
                if (item.label == val.label) {
                    item.open = !item.open
                }
                if (!item.open) {
                    this.data.style.descriptionArr.splice(index, 1)
                }
            })

        }
    }
}
</script>

<style lang="scss" scoped>
.lable {
    margin-right: 16px;
    font-size: 12px;
    color: #bbbbbb;
    line-height: 18px;
    white-space: nowrap;

}

.btnListBox {
    border-radius: 5px;

    .item {

        height: 30px;
        border: 1px solid #ebedf0;
        padding: 0 20px;
        cursor: pointer;
    }

    .borderColor {
        border: 1px solid #1c75f4;
    }
}

.imgBox {
    width: 100px;
    height: 100px;
    border: 1px solid #e5e5e5;
    color: #155bd4;
    font-size: 12px;
    background: #f2f4f6;

    .img {
        width: 100%;
        height: 100%;
    }
}

.descriptionArr {
    width: 100%;
    color: #333333;

    .item {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #dedede;
    }
}

//拖动
.slider {
    width: calc(100% - 106px);
}

//圆角
.radiuDistance {
    margin-right: 62px;
}
.color000 {
    color: #000 !important;
}
</style>
