<template>
  <div class="wenben">
    <MySysForm v-model="itemData" :options="options" @change="change" />
  </div>
</template>

<script>
import MySysForm from "@/components/my-sys-form/index.vue";
export default {
  name: "wenben",
  components: { MySysForm },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      options: [
        {
          type: "input",
          model: "title",
          option: {
            label: "标题内容",
          },
        },
        {
          type: "textarea",
          model: "subhead",
          option: {
            label: "说明内容",
            maxlength: 500,
          },
        },
        {
          type: "miniAppUrl",
          model: "link",
          option: {
            label: "跳转链接",
          },
        },
        { type: "divider" },
        {
          type: "icon-buttons",
          model: "style$$location",
          option: {
            buttonLabel: "显示位置",
            dictType: "TEXT_ALIGN",
          },
        },
        {
          type: "icon-buttons",
          model: "style$$titleFontSize",
          option: {
            buttonLabel: "标题大小",
            dataKey: "fontSize",
            dictType: "TEXT_SIZE",
          },
        },
        {
          type: "icon-buttons",
          model: "style$$subheadFontSize",
          option: {
            buttonLabel: "描述大小",
            dataKey: "fontSize",
            dictType: "TEXT_SIZE",
          },
        },
        {
          type: "icon-buttons",
          model: "style$$titleWeight",
          option: {
            buttonLabel: "标题加粗",
            dataKey: "weight",
            dictType: "TEXT_BOLD",
          },
        },
        {
          type: "icon-buttons",
          model: "style$$subheadWeight",
          option: {
            buttonLabel: "描述加粗",
            dataKey: "weight",
            dictType: "TEXT_BOLD",
          },
        },
        {
          type: "colors",
          model: "style$$titleColor",
          option: {
            buttonLabel: "标题颜色",
          },
        },
        {
          type: "colors",
          model: "style$$subheadColor",
          option: {
            buttonLabel: "描述颜色",
          },
        },
        {
          type: "colors",
          model: "style$$bgColor",
          option: {
            buttonLabel: "背景颜色",
          },
        },
      ],
      titleWeightLiSt: [{ weight: "" }, { weight: "bold" }],
      titleSizeItem: 0,
      subSizeItem: 0,
      titleWeightItem: 0,
      subWeightItem: 0,
      itemData: this.data,
    };
  },
  methods: {
    change(e) {
      this.$emit("setTemp",this.itemData);
      this.$nextTick()
      this.$forceUpdate()
    },
  },
};
</script>
