<template>
  <div style="width: 100%">
    <!-- 背景颜色 -->
    <div class="marB20">
      <div class="marB20 x-bc">
        <div class="fontS14c3">头部背景:</div>
        <div class="x-x">
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.style.bgColorType === 'bgc' }"
            @click="getShow('style', 'bgColorType', 'bgc')"
          >
            颜色
          </div>
          <div
            class="simpleBtn cursorP"
            :class="{ active: data.style.bgColorType === 'img' }"
            @click="getShow('style', 'bgColorType', 'img')"
          >
            图片
          </div>
        </div>
      </div>
      <div v-show="data.style.bgColorType" class="boxDetail marL120">
        <div class="x-bc" v-show="data.style.bgColorType === 'bgc'">
          <div class="x-f">
            <span class="fontS14c3 marR20">背景颜色:</span>
            <span class="fontS14c3">{{ data.style.bgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.bgColor" />
          </div>
        </div>
        <div class="x-x" v-show="data.style.bgColorType === 'img'">
          <span class="fontS14c3 marR20">背景图片:</span>
          <div class="selectIconBox x-fc marL10" @click="getMaterialOpen">
            <i
              v-show="!data.style.bgImgUrl"
              class="el-icon-plus"
              style="font-size: 32px"
            ></i>
            <el-image
              v-show="data.style.bgImgUrl"
              style="width: 70px; height: 70px"
              :src="data.style.bgImgUrl"
            />
            <span v-show="data.style.bgImgUrl" class="replace">替换</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 昵称颜色 -->
    <div class="marB20 x-bc">
      <div class="x-f">
        <span class="fontS14c3 marR20">昵称颜色:</span>
        <span class="fontS14c3">{{ data.style.nameColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.style.nameColor" />
      </div>
    </div>
    <!-- 会员等级1 -->
    <!-- <div class="marB20 x-b">
      <span class="fontS14c3">会员等级1:</span>
      <div class="boxDetail">
         图标
        <div class="x-x marB20">
          <span class="fontS14c3 marR20">图标:</span>
          <div
            class="selectIconBox x-fc marL10"
            @click="getSelectIcon('会员等级1')"
          >
            <i
              v-show="!data.vipLevelIcon"
              class="el-icon-plus"
              style="font-size: 32px"
            ></i>
            <iconParkALi
              v-show="data.vipLevelIcon"
              :name="data.vipLevelIcon"
              size="32px"
            />
            <span v-show="data.vipLevelIcon" class="replace">替换</span>
          </div>
        </div>
         背景颜色
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">背景颜色:</span>
            <span class="fontS14">{{ data.style.vipLevelBgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.vipLevelBgColor" />
          </div>
        </div>
        文字颜色
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">文字颜色:</span>
            <span class="fontS14">{{ data.style.vipLevelTextColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.vipLevelTextColor" />
          </div>
        </div>
         跳转链接
        <div class="marB20 x-f link">
          <span class="fontS14c3 marR20">链接:</span>
          <el-input
            class="inputWidth"
            size="mini"
            v-model="data.vipLevelLink.name"
            readonly
          >
          </el-input>
          <span class="marL5 fontS14B cursorP" @click="getOpenLink">{{
            data.vipLevelLink.name ? '修改' : '选择'
          }}</span>
        </div>
      </div>
    </div> -->
    <!-- 会员等级 -->
    <div class="marB20 x-b">
      <span class="fontS14c3">会员等级:</span>
      <div class="boxDetail">
        <!-- 图标 -->
        <div class="x-x marB20">
          <span class="fontS14c3 marR20">图标:</span>
          <div class="selectIconBox x-fc marL10" @click="getSelectIcon">
            <i
              v-show="!data.vipLevelIcon"
              class="el-icon-plus"
              style="font-size: 32px"
            ></i>
            <iconParkALi
              v-show="data.vipLevelIcon"
              :name="data.vipLevelIcon"
              size="32px"
            />
            <span v-show="data.vipLevelIcon" class="replace">替换</span>
          </div>
        </div>
        <!-- 背景颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">背景颜色:</span>
            <span class="fontS14">{{ data.style.vipLevelBgColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.vipLevelBgColor" />
          </div>
        </div>
        <!--图标颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">图标颜色:</span>
            <span class="fontS14">{{ data.style.vipLevelIconColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.vipLevelIconColor" />
          </div>
        </div>
      </div>
    </div>
    <!-- 财务设置 -->
    <div class="marB20">
      <span class="fontS14c3">财务按钮:</span>
      <el-checkbox-group class="marT10" v-model="data.FinancialBtnList">
        <el-checkbox label="余额"></el-checkbox>
        <el-checkbox label="积分"></el-checkbox>
        <el-checkbox label="优惠券"></el-checkbox>
        <el-checkbox label="收藏"></el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- 财务样式 -->
    <div class="marB20 x-b">
      <span class="fontS14c3">财务样式:</span>
      <div class="boxDetail">
        <!-- 数字颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">数字颜色:</span>
            <span class="fontS14">{{ data.style.financeNumColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.financeNumColor" />
          </div>
        </div>
        <!--标题颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">标题颜色:</span>
            <span class="fontS14">{{ data.style.financeTitleColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.financeTitleColor" />
          </div>
        </div>
        <!--分割线颜色 -->
        <div class="x-bc marB20">
          <div class="x-f">
            <span class="fontS14c3 marR20">分割线颜色:</span>
            <span class="fontS14">{{ data.style.financeDividerColor }}</span>
          </div>
          <div class="x-f marL10">
            <el-color-picker v-model="data.style.financeDividerColor" />
          </div>
        </div>
      </div>
    </div>

    <!-- 用户导入图片对话框 -->
    <el-dialog  v-dialogDrag title="素材库" :visible.sync="materialOpen" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectIcon :OpenIcon.sync="openIcon" @selectIcon="selectIcon" />
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
import selectIcon from '@/views/components/selectIcon/index.vue' //图标库
import selectLink from '@/views/components/selectLink/index.vue' //链接库
export default {
  name: 'huiyuanxinxi',
  components: { Material, selectIcon, selectLink },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      //链接弹窗
      openLink: false,
      //图标库窗
      openIcon: false,
      //筛选出来的图片地址数组
      accessUrlList: [],
      //素材库弹窗
      materialOpen: false,
      //标题字体数组
      titleSizeList: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      //位置class类
      locationItem: 1,
      //位置信息
      locationList: [
        {
          name: 'align-text-left',
          style: 'left'
        },
        {
          name: 'align-text-center',
          style: 'center'
        }
      ]
    }
  },
  methods: {
    //链接赋值
    selectLinkObj (value) {
      this.data.vipLevelLink = value
      this.templateMsg()
    },
    //打开链接库
    getOpenLink () {
      this.openLink = true
    },
    //选择好的图标
    selectIcon (value) {
      this.data.vipLevelIcon = value
      this.templateMsg()
    },
    //打开图标库
    getSelectIcon () {
      this.openIcon = true
    },
    //上传图片弹窗取消
    imgUpdateCancel () {
      this.accessUrlList = []
      this.materialOpen = false
    },
    //上传图片弹窗确定
    imgUpdateSubmit () {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.data.style.bgImgUrl = this.accessUrlList[0]
      this.accessUrlList = []
      this.materialOpen = false
      this.templateMsg()
    },
    //图片筛出来的url
    getAccessUrlList (value) {
      this.accessUrlList = value
    },
    //打开素材库
    getMaterialOpen () {
      this.materialOpen = true
    },
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (typeobj, type, bol) {
      this.data[typeobj][type] = bol
      this.templateMsg()
    },
    //外部是或否
    getShowWithout (type, bol) {
      this.data[type] = bol
      this.templateMsg()
    },
    // 位置设置
    setLocation (str, index) {
      this.data.titlePosition = str
      this.locationItem = index
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
//选择图标
.selectIconBox {
  width: 70px;
  height: 70px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  position: relative;
  //替换
  .replace {
    position: absolute;
    height: 20px;
    left: 1px;
    right: 1px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    line-height: 20px;
    margin: 0;
    text-align: center;
    display: none;
  }
}
//替换
.selectIconBox:hover .replace {
  display: inline-block;
}
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//边框详情样式
.boxDetail {
  min-width: 250px;
  padding: 15px;
  background-color: #f8f9ff;
}
//右边距离120px
.marL120 {
  margin-left: 120px;
}

/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
/* 复选框选中后的字体颜色 */
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #303133 !important;
}
</style>
