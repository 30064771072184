<template>
  <div style="width: 100%">
    <!-- 选择导航样式 -->
    <div class="marB20">
      <span class="fontS14c3">侧边导航:</span>
      <div class="x-fc marT10">
        <div
          class="displayMode marR15"
          v-for="(item, index) in navigationList"
          :key="index"
          :class="{ active: data.sideNavStyle.sideNavType === index }"
          @click="getSideNavListStyle(item.sideNavType)"
        >
          <div class="icon" >
            <iconpark-icon v-if="index<=3" :name="item.icon" size="22px" />
             <div v-else>
            <img style="width:22px;height:22px" :src="item.icon"/>
          </div>
          </div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 是否启用瀑布流 -->
    <div
      class="marB20 x-bc"
      v-show="data.sideNavStyle.sideNavType == 2 || data.sideNavStyle.sideNavType == 3"
    >
      <div class="fontS14c3">瀑布流方式加载跨分类商品</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.sideNavStyle.isWaterfallFlow }"
          @click="getShow('sideNavStyle', 'isWaterfallFlow', true)"
        >
          启用
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.sideNavStyle.isWaterfallFlow }"
          @click="getShow('sideNavStyle', 'isWaterfallFlow', false)"
        >
          不启用
        </div>
      </div>
    </div>
    <!-- 瀑布流加载方式 -->
    <!-- <div
      class="waterfallLoadingMode marB20"
      v-show="(data.sideNavStyle.sideNavType == 2 || data.sideNavStyle.sideNavType == 3)&&data.sideNavStyle.isWaterfallFlow"
    >
      <span class="fontS14c3">加载方式:</span>
      <el-radio-group
        v-model="data.sideNavStyle.loadingMode"
        class="marT10 x-f"
      >
        <el-radio :label="0"
          >强滑动加载跨分类商品
          <el-tooltip placement="top">
            <div slot="content">
              流畅度一般，但是支持无限商品。
              <br />
              请使用2020.12.18及之后发布的小程序模版。
            </div>
            <i class="iconfont icon-wenhao" style="color: #a4a4a4"></i>
          </el-tooltip>
        </el-radio>
        <el-radio :label="1"
          >一次性加载全部商品
          <el-tooltip placement="top">
            <div slot="content">
              商品少于200时流畅度最优，但是有以下局限：
              <br />
              1、单分类最多50个商品，最多20个分类。
              <br />
              2、分类只显示一级分类，ps 二级以下分类会平铺到一级显示。
              <br />
              3、商品不支持开启排序条件。
              <br />
              4、暂不支持不支持表情分类。
              <br />
              请使用2020.12.18及之后发布的小程序模版。
            </div>
            <i
              class="iconfont icon-wenhao"
              style="color: #a4a4a4"
            ></i> </el-tooltip
        ></el-radio>
      </el-radio-group>
    </div> -->
    <!-- 瀑布流加载方式 -->
    <!-- <div
      class="waterfallLoadingMode marB20"
      v-show="data.sideNavStyle.isWaterfallFlow"
    >
      <span class="fontS14c3">加载方式:</span>
      <el-radio-group
        v-model="data.sideNavStyle.loadingMode"
        class="marT10 x-f"
      >
        <el-radio :label="0"
          >强滑动加载跨分类商品
          <el-tooltip content="流畅度一般，但是支持无限商品。" placement="top">
            <i class="iconfont icon-wenhao" style="color: #a4a4a4"></i>
          </el-tooltip>
        </el-radio>
        <el-radio :label="1"
          >一次性加载全部商品
          <el-tooltip placement="top">
            <div slot="content">
              商品少于200时流畅度最优，但是有以下局限：
              <br />
              1、单分类最多50个商品，最多20个分类。
              <br />
              2、分类只显示一级分类，ps 二级以下分类会平铺到一级显示。
              <br />
              3、商品不支持开启排序条件。
            </div>
            <i
              class="iconfont icon-wenhao"
              style="color: #a4a4a4"
            ></i> </el-tooltip
        ></el-radio>
      </el-radio-group>
    </div> -->
    <!-- 商品展示方式 -->
    <div class="goodsDisplayMode marB20">
      <span class="fontS14c3">商品展示样式:</span>
      <div class="x-fc marT10">
        <div
          class="displayMode marR20"
          v-for="(item, index) in listStyle"
          :key="index"
          :class="{ active: data.goodsStyle.goodsType === index }"
          @click="getGoodsListStyle(item.goodsType)"
        >
          <div class="icon">
            <iconpark-icon :name="item.icon" size="22px" />
          </div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 是否显示库存 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">库存</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.goodsStyle.isInventory }"
          @click="getShow('goodsStyle', 'isInventory', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.goodsStyle.isInventory }"
          @click="getShow('goodsStyle', 'isInventory', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <div class="marB20 x-bc"  v-if="((data.sideNavStyle.sideNavType!=2&&data.sideNavStyle.sideNavType!=3)||!data.sideNavStyle.isWaterfallFlow)">
      <div class="fontS14c3">是否开启排序条件</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.goodsStyle.isSortData }"
          @click="getShow('goodsStyle', 'isSortData', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.goodsStyle.isSortData }"
          @click="getShow('goodsStyle', 'isSortData', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 是否显示销量 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">销量</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.goodsStyle.isSalesVolume }"
          @click="getShow('goodsStyle', 'isSalesVolume', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.goodsStyle.isSalesVolume }"
          @click="getShow('goodsStyle', 'isSalesVolume', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 是否显示价格标签 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">价格标签</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.goodsStyle.isPriceTag }"
          @click="getShow('goodsStyle', 'isPriceTag', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.goodsStyle.isPriceTag }"
          @click="getShow('goodsStyle', 'isPriceTag', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 是否显示分类管理上传的分类海报 -->
    <div class="marB20 x-bc">
      <div class="fontS14c3">分类管理上传的分类海报</div>
      <div class="x-x">
        <div
          class="simpleBtn cursorP"
          :class="{ active: data.goodsStyle.isPoster }"
          @click="getShow('goodsStyle', 'isPoster', true)"
        >
          显示
        </div>
        <div
          class="simpleBtn cursorP"
          :class="{ active: !data.goodsStyle.isPoster }"
          @click="getShow('goodsStyle', 'isPoster', false)"
        >
          不显示
        </div>
      </div>
    </div>
    <!-- 标题字体大小 -->
    <div class="marB20 x-bc">
      <span class="fontS14c3">标题字体大小</span>
      <el-select
        class="titleSizeSelect"
        size="mini"
        v-model="data.goodsStyle.titleSize"
        filterable
        ><el-option
          v-for="item in titleSizeList"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <!-- 价格字体大小 -->
    <div class="marB20 x-bc">
      <span class="fontS14c3">价格字体大小</span>
      <el-select
        class="titleSizeSelect"
        size="mini"
        v-model="data.goodsStyle.priceSize"
        filterable
        ><el-option
          v-for="item in priceSizeList"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <!-- 标题颜色 -->
    <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">标题颜色</span>
        <span class="fontS14">{{ data.goodsStyle.titleColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.goodsStyle.titleColor"></el-color-picker>
      </div>
    </div>
    <!-- 价格标签颜色 -->
    <!-- <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">价格标签颜色</span>
        <span class="fontS14">{{ data.goodsStyle.priceTagColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker
          v-model="data.goodsStyle.priceTagColor"
        ></el-color-picker>
      </div>
    </div> -->
    <!-- 价格颜色 -->
    <!-- <div class="x-bc marB20">
      <div>
        <span class="fontS14 marR20">价格颜色</span>
        <span class="fontS14">{{ data.goodsStyle.priceColor }}</span>
      </div>
      <div class="x-f marL10">
        <el-color-picker v-model="data.goodsStyle.priceColor"></el-color-picker>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'shangpindaohang',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      //标题字体数组
      titleSizeList: [10,12,14, 16, 18],
      //价格字体数组
      priceSizeList: [10,12,14, 16, 18, 20],
      //导航数组
      navigationList: [
        { icon: 'danhang1', text: '多级文字分类', sideNavType: 0 },
        { icon: 'danhang2', text: '多级图标分类', sideNavType: 1 },
        { icon: 'danhang3', text: '一级图标分类', sideNavType: 2 },
        { icon: 'danhang4', text: '一级图标分类2', sideNavType: 3 },
        { icon: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-08-30/7123e0fa5454480c957c37cadfdf59b0.png', text: '三级分类展示', sideNavType: 4 },
        { icon: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-08-30/435f890043de411e94a8a1c656942298.png', text: '一级图标分类3', sideNavType: 5 }
      ],
      //商品列表样式数组
      listStyle: [
        { icon: 'yihangpailie', text: '一行一个', goodsType: 0 },
        { icon: 'lianghangpailie', text: '一行两个', goodsType: 1 },
        { icon: 'datuxianshi', text: '大图模式', goodsType: 2 }
      ]
    }
  },
  methods: {
    //更多设置区域点击事件 type区分类型 pitchVal选中的值
    getShow (typeObj, type, bol) {
      this.data[typeObj][type] = bol
      this.templateMsg()
    },
    //侧板导航展示样式
    getSideNavListStyle (sideNavType) {
      this.data.sideNavStyle.sideNavType = sideNavType
      this.templateMsg()
    },
    //商品列表展示样式
    getGoodsListStyle (goodsType) {
      this.data.goodsStyle.goodsType = goodsType
      this.templateMsg()
    },
    templateMsg () {
      this.$emit('setTemp', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
//简单按钮样式
.simpleBtn {
  text-align: center;
  border: 1px solid #dcdee0;
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  &.active {
    background: #edf4ff;
    border: 1px solid #155bd4;
    color: #155bd4;
  }
}
//标题选择
.titleSizeSelect {
  width: 100px;
}
//展示列表
.displayMode {
  .icon {
    width: 56px;
    height: 56px;
    padding: 17px;
    border: 1px solid #e0e0e0;
    margin-bottom: 8px;
  }
  .text {
    color: #999;
  }
  //焦点
  &.active {
    color: #155bd4;
  }
  &.active .icon {
    background: #edf4ff;
    border: 1px solid #155bd4;
  }
  &.active .text {
    color: #155bd4;
  }
}
//瀑布流加载方式
.waterfallLoadingMode {
  ::v-deep .el-radio {
    margin-right: 18px;
  }
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
</style>
